import "../portfolioPage.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import { generateImgArray } from "../../../components/portfolio/utils"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"
import VimeoVideo from "../../../components/portfolio/VimeoVideo"
import Lightbox from "../../../components/portfolio/Lightbox"

import LogoLuz from "../../../svg/portfolio/logoLuz.svg"
import LuzBg from "../../../svg/portfolio/luzBg.svg"
import { Parallax } from "react-scroll-parallax"

export default function IndexPage({ data }) {
  const { t } = useTranslation()
  const images = data.images.edges

  return (
    <Layout territory={"art"}>
      <Seo title={"Luz"} />

      <PatternLateral territory="art" />
      <h2 className="sectionTitle basic">
        <LogoLuz className="portfolioFileTitle" id="logoLuz" />
      </h2>

      <div className="content portfolioFileContent">
        <div className="filmDetails">
          <span>
            <Trans>Feature film</Trans>
          </span>
          <span className="year">
            <Trans>Coming soon</Trans>
          </span>
        </div>

        <VimeoVideo
          videoId="691307215"
          title={t("Teaser trailer of Luz, the Shaman Girl")}
          aspectRatio={1 / 1.845}
          autoplay={false}
        >
          <p>
            <Trans>Teaser video</Trans>
          </p>
        </VimeoVideo>

        {/*
        <p className="sinopsis">
          <Trans>
            What if the climate marches started 400.000 years ago? Luz tells the
            story of our collective consciousness asking for a global agreement
            against climate change.
            <br /> <br />
            An amazing story based on a real archeological finding. One magical
            adventure to connect past and present with our Earth.
          </Trans>
        </p>
         
        <div className="sectionTitle">
          <h2>
            <Trans>Characters</Trans>
          </h2>
          <Parallax speed={3} className="titleBg">
            <LuzBg />
          </Parallax>
        </div>

        <div className="subsectionTitle">
          <Lightbox images={[images[0]]} />
          <h3>Luz</h3>
          <span>
            <Trans>The shaman girl</Trans>
          </span>
          <p>
            <Trans>
              Scared and bewildered, our protagonist starts the story fleeing
              her home. A little girl facing the most dangerous clan in the
              mountains, she will have to learn how to assume her important
              responsibility before it is too late for everyone.
            </Trans>
          </p>
        </div>
        <Lightbox className="imgGrid" images={generateImgArray(images, 1, 2)} />

        <div className="subsectionTitle">
          <Lightbox images={[images[25]]} />
          <h3>
            <Trans>The Stones clan</Trans>
          </h3>
          <p>
            <Trans>
              Guardians of the heart of the world, the Stones Clan sees how its
              history fades due to oblivion and how the Clan of Fire wants to
              take its place. The adventure of Luz and Wolf will reveal the true
              story of the Stones Clan and its vital importance for the forest.
            </Trans>
          </p>
        </div>
        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 26, 28)}
        />

        <div className="subsectionTitle">
          <Lightbox images={[images[9]]} />
          <h3>
            <Trans>The Deers clan</Trans>
          </h3>
          <p>
            <Trans>
              Strong and agile, their mission has always been to take care of
              the forest and to keep it healthy but, with the arrival of the
              Fire Clan, fear has taken control of them and now they are hidden
              in the mist.
            </Trans>
          </p>
        </div>
        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 10, 14)}
        />

        <div className="subsectionTitle">
          <Lightbox images={[images[20]]} />
          <h3>
            <Trans>The Forest clan</Trans>
          </h3>
          <p>
            <Trans>
              The Forest Clan elders keep the ancestral wisdom of the planet;
              the one that resides in the deepest of the universe. They are
              aware of all history but they don't reveal anything: they want you
              to discover it.
            </Trans>
          </p>
        </div>
        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 21, 24)}
        />

        <div className="subsectionTitle">
          <Lightbox images={[images[3]]} />
          <h3>
            <Trans>The Bees clan</Trans>
          </h3>
          <p>
            <Trans>
              The Bee families protect and take care of the insects and flowers
              because the life of the forest depends on them. However, after the
              incident with the Clan of Fire, they become sick and stop singing.
            </Trans>
          </p>
        </div>
        <Lightbox className="imgGrid" images={generateImgArray(images, 4, 8)} />

        <div className="subsectionTitle">
          <Lightbox images={[images[15]]} />
          <h3>
            <Trans>The Fire clan</Trans>
          </h3>
          <p>
            <Trans>
              After managing to dominate fire, his next goal is to subdue all
              clans of Atapuerca to obtain the mysterious stone. Their leader,
              insatiable in his will to impose his law through fire, leads a
              clan that has forgotten its past.
            </Trans>
          </p>
        </div>
        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 16, 19)}
        />

        <div className="sectionTitle">
          <h2>
            <Trans>The world</Trans>
          </h2>
          <Parallax speed={3} className="titleBg">
            <LuzBg />
          </Parallax>
        </div>

        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 29, 34)}
        />

        <Lightbox images={[images[35]]} />

        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 36, 37)}
        />
        <Lightbox images={[images[38]]} /> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/^portfolio/luz//" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
          name
          publicURL
        }
      }
    }
  }
`
